import { useDisclosure } from '@mantine/hooks';
import { HttpStatus } from 'api/HttpStatus';
import type { ServiceCallError } from 'api/ServiceCallError';
import { type JSX, useEffect } from 'react';
import { Zippy } from 'ts/base/components/Zippy';
import { useUserPermissionInfo } from 'ts/base/hooks/PermissionInfoHook';
import { useNavigationHash } from 'ts/base/hooks/UseNavigationHash';
import { TeamscaleSupportModal } from 'ts/base/scaffolding/TeamscaleSupportModal';
import { NavigationUtils } from 'ts/commons/NavigationUtils';
import { Button } from 'ts/components/Button';
import { Icon } from 'ts/components/Icon';
import { Message, MessageHeader } from 'ts/components/Message';
import { EGlobalPermission } from 'typedefs/EGlobalPermission';
import styles from './ServiceErrorComponent.module.less';

/** The props for ServiceErrorComponent. */
type ErrorProps = {
	error: ServiceCallError;
	resetErrorBoundary: () => void;
};

/**
 * Renders an error to the requested page. It may be possible that more than 1 error could be encountered as a page
 * loads. Therefore, this method considers this scenario too. Though there may be UI elements on the side bar, none are
 * displayed on the main container except error messages.
 *
 * In case a '401 Unauthorized' error happens, a reload of the current page is triggered. This will show the login
 * screen and redirect back to the current page after login.
 */
export function ServiceErrorComponent({ error, resetErrorBoundary }: ErrorProps): JSX.Element | null {
	if (error.statusCode === HttpStatus.UNAUTHORIZED) {
		return <UnauthorizedError resetErrorBoundary={resetErrorBoundary} />;
	}
	if (error.statusCode === HttpStatus.NOT_FOUND) {
		return (
			<BaseErrorTemplate
				message={error.message || 'the page you requested does not exist.'}
				resetErrorBoundary={resetErrorBoundary}
			/>
		);
	}
	if (error.statusCode === HttpStatus.FORBIDDEN) {
		return (
			<BaseErrorTemplate
				message={`you are missing permissions for the requested operation: ${error.message}`}
				resetErrorBoundary={resetErrorBoundary}
			/>
		);
	}
	return <RegularServiceError error={error} resetErrorBoundary={resetErrorBoundary} />;
}

function UnauthorizedError({ resetErrorBoundary }: { resetErrorBoundary: () => void }) {
	const hash = useNavigationHash();
	useEffect(() => {
		NavigationUtils.updateLocation('/login?target=' + encodeURIComponent(hash.toString()), true);
		resetErrorBoundary();
	}, [hash, resetErrorBoundary]);
	return null;
}

/**
 * A component for rendering server-side errors on the UI after with toggling functionality for displaying more details
 * of the error may be prepared. A short summary of it is first shown. This is helpful to avoid 'flooding' the user with
 * technical details. Provision is made on UI for viewing the technical description.
 */
function RegularServiceError({ error, resetErrorBoundary }: ErrorProps): JSX.Element {
	return (
		<div>
			<Message error className={styles.serviceCallError}>
				<MessageHeader>&#9785; {error.errorSummary}</MessageHeader>
				<TryAgainButton resetErrorBoundary={resetErrorBoundary} />
				<Zippy header="Details" storageKey="error-details-section">
					The service call failed with an error.
					<br />
					Error code {error.statusCode}: {error.message}
					<br />
					<br />
					<SupportDialogLink />
					<h4 style={{ marginTop: '20px' }}>Technical Description</h4>
					<pre style={{ marginTop: '5px' }}>{error.technicalDetails}</pre>
				</Zippy>
			</Message>
		</div>
	);
}

/** The props for BaseErrorTemplate. */
type BaseErrorTemplateProps = {
	message: string;
	resetErrorBoundary: () => void;
};

function TryAgainButton({ resetErrorBoundary }: { resetErrorBoundary: () => void }) {
	return (
		<Button size="small" icon={<Icon name="redo alternate" />} content="Try again" onClick={resetErrorBoundary} />
	);
}

/** Shows a basic error message prefixed with Sorry, ... with a link to create a support request. */
export function BaseErrorTemplate({ message, resetErrorBoundary }: BaseErrorTemplateProps): JSX.Element {
	return (
		<div>
			<Message error className={styles.baseError}>
				<MessageHeader>Sorry, {message}</MessageHeader>
				<SupportDialogLink />
				<TryAgainButton resetErrorBoundary={resetErrorBoundary} />
			</Message>
		</div>
	);
}

/** Shows a link to create a support request. Shows nothing when the user does not have the necessary permissions. */
function SupportDialogLink(): JSX.Element | null {
	const permissionInfo = useUserPermissionInfo();
	const [isTeamscaleSupportModalOpen, { open: openTeamscaleSupportModal, close: closeTeamscaleSupportModal }] =
		useDisclosure(false);
	if (!permissionInfo.hasGlobalPermission(EGlobalPermission.CREATE_SUPPORT_REQUEST)) {
		return null;
	}
	return (
		<>
			<h4>
				<a
					className={styles.supportDialogLauncher}
					onClick={(event): void => {
						event.preventDefault();
						openTeamscaleSupportModal();
					}}
				>
					Submit a Support Request.
				</a>
			</h4>
			{isTeamscaleSupportModalOpen ? <TeamscaleSupportModal onClose={closeTeamscaleSupportModal} /> : null}
		</>
	);
}
